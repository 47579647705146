<template>
  <button>JJ</button>
</template>
<script lang="ts">
export default {
  name: "AccountBadge",
  // TODO: Account popup
};
</script>
<style lang="scss" scoped>
button {
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  margin: 1em;
}
</style>