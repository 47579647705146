
import { IonIcon } from "@ionic/vue";
import { filterOutline } from "ionicons/icons";

export default {
  name: "FilterButton",
  components: {
    IonIcon,
  },
  setup() {
    return {
      filterOutline,
    };
    // TODO: Filter component
  },
};
