
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonSearchbar,
  IonRow,
  IonFab,
  IonFabButton,
  IonIcon
} from "@ionic/vue";
import AccountBadge from "@/components/account/AccountBadge.vue";
import FilterButton from "@/components/filter/FilterButton.vue";
import { addCircleOutline } from "ionicons/icons";
import { useStore } from "@/services/store";

export default {
  name: "Projects",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonSearchbar,
    IonRow,
    IonFab,
    IonFabButton,
    IonIcon,
    AccountBadge,
    FilterButton
  },
  setup() {
    const store = useStore();

    // TODO: Projects

    // created(){

    // }

    return {
      addCircleOutline
    };
  }
};
